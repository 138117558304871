import { AbstractControl } from '@angular/forms';
import { Chain } from '@shared/utils';
import { Observable, startWith, tap } from 'rxjs';

export const formControlValue = <T extends AbstractControl>(control: T) => control.valueChanges.pipe(startWith(control.value)) as Observable<T['value']>;

export const setFormControlEnabled = <T extends AbstractControl>(isEnabled: (value: unknown) => boolean, control: T) =>
	(source: Observable<T>) => source.pipe(
		tap(value => Chain.of(isEnabled(value)).iifThis(() => control.enable(), () => control.disable())));


